import * as rudderstack from "rudder-sdk-js";

export default ({ app: { router } }, inject) => {
    // rudderstack.load(process.env.RUDDERSTACK_WRITE_KEY, process.env.RUDDERSTACK_DATA_PLANE_URL);
    // rudderstack.ready(() => {
    //     console.debug('[ANALYTICS] Ready');
    // });

    // router.afterEach((to) => {
    //     setTimeout(() => {
    //         rudderstack.page(null, to.name);
    //     }, 500);
    // });

    inject('analytics', {
        identify() {
            console.debug('Identify User');
        },
        track() {
            console.debug('Track Event');
        }
    });
};
