import Vue from 'vue';
import { library, config, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { faTwitter, faGoogle, faInstagram, faWindows } from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = true;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(far, faTwitter, faGoogle, faInstagram, faWindows);

Vue.component('font-awesome-icon', FontAwesomeIcon)

window.addEventListener('DOMContentLoaded', () => {
    dom.i2svg()
    dom.watch()
})
