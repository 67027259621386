
import { mapState } from 'vuex';

export default {
    middleware: 'auth',
    computed: {
        ...mapState('auth', ['isLoggedIn', 'user']),
        isDevelopment() {
            return process.env.APP_ENV === 'development';
        }
    }
};
