
import { mapState } from 'vuex';
import Navbar from '~/components/layout/Navbar.vue';

export default {
    components: {
        Navbar,
    },
    computed: {
        ...mapState('ui', ['desktopSidebarOpen']),
    },
}
