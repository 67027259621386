
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    computed: {
        ...mapGetters('auth', ['role']),
        ...mapState('auth', ['user', 'provider']),
    },
    methods: {
        ...mapActions('auth', ['logout']),
    },
};
