import moment from 'moment';
import { decode } from 'jsonwebtoken';
import { onIdTokenChanged, getIdTokenResult } from 'firebase/auth';

let interval;

export default async ({ store, $auth }) => {
    if (process.env.APP_ENV !== 'production') console.debug('[MIDDLEWARE] Initialize Auth');
    return new Promise((resolve) => {
        clearInterval(interval);
        interval = setInterval(async () => {
            if ($auth.currentUser && store.state.auth.token) {
                const currentTime = Math.ceil(new Date().getTime() / 1000);
                const token = decode(store.state.auth.token);
                const expire = token.exp;
                const fiveMinutes = 60 * 5;
                const refresh = expire && (expire - fiveMinutes) < currentTime;

                if (process.env.APP_ENV !== 'production') console.log('Expiring', moment.unix(expire).toDate());

                if (refresh) {
                    if (process.env.APP_ENV !== 'production') console.groupCollapsed('Refreshing token!');
                    const jwt = await getIdTokenResult($auth.currentUser, true);
                    await store.dispatch('auth/login', { user: $auth.currentUser, jwt });
                    if (process.env.APP_ENV !== 'production') console.groupEnd();
                } else {
                    if (process.env.APP_ENV !== 'production') console.info('Token is still valid');
                }
            }
        }, 5 * 60 * 1000);
        const listener = onIdTokenChanged($auth, async (user) => {
            listener();
            if (user) {
                const jwt = await getIdTokenResult(user);
                await store.dispatch('auth/login', { user, jwt });

                return resolve(true);
            }

            await store.dispatch('auth/onLogout');

            return resolve(false);
        });
    });
}
