
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('auth', ['user']),
    },
    mounted() {
        window.courierConfig = {
            clientKey: process.env.COURIER_CLIENT_KEY,
            userId: this.user.uid,
            initOnLoad: false,
        };

        if (window.courier) {
            window.courier.init();
        }
    }
};
