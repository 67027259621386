
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ROLE_ADMIN, ROLE_DIRECTOR, ROLE_PRACTITIONER } from '~/constants/roles';
import SubscribeToMemberships from '~/graphql/subscriptions/users/SubscribeToMemberships';

export default {
    data() {
        return {
            ROLE_ADMIN,
            ROLE_DIRECTOR,
            ROLE_PRACTITIONER,
            memberships: [],
        };
    },
    computed: {
        ...mapGetters('auth', ['role']),
        ...mapState('auth', ['user', 'provider']),
    },
    apollo: {
        $subscribe: {
            memberships: {
                query: SubscribeToMemberships,
                variables() {
                    return {
                        user_id: this.user.uid
                    }
                },
                result({ data }) {
                    this.memberships = data.memberships || [];
                }
            }
        }
    },
    methods: {
        ...mapMutations('ui', ['toggleMobileSidebar']),
        ...mapActions('auth', ['logout']),
    }
};
