export default ({ store, redirect, route, $auth, $sentry }) => {
    if(process.env.APP_ENV !== 'production') console.debug('[MIDDLEWARE] Guest');
    try {
        if (store.state.auth.isLoggedIn && $auth.currentUser && route.matched.length && route.path.includes('auth')) {
            return redirect({ name: 'index' });
        }
    } catch(error) {
        if(process.env.APP_ENV !== 'production') console.error(error);
        $sentry.captureException(error, {
            tags: {
                location: 'middleware',
                middleware: 'guest',
            }
        });
    }
}
