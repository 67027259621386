import { LOCAL_STORAGE_REDIRECT_AFTER_INVITE } from '~/constants/local-storage';

export default async ({ redirect, route, app: { $sentry, store } }) => {
    console.debug('[MIDDLEWARE] Require a practice');
    try {
        if (route.matched.length && !store.state.auth.provider) {
            const invitation = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_INVITE);

            if (invitation) {
                return redirect({ name: 'invitations-invitation', params: { invitation} });
            }

            return redirect({ name: 'setup' });
        }

        return true;
    } catch(error) {
        $sentry.captureException(error, {
            tags: {
                location: 'middleware',
                middleware: 'require-practice'
            }
        })
    }
}
