
import { mapMutations, mapState } from 'vuex';
import Notifications from '~/components/layout/Notifications';

export default {
    components: {
        Notifications,
    },
    computed: {
        ...mapState('ui', ['desktopSidebarOpen']),
        ...mapState('auth', ['user']),
    },
    methods: {
        ...mapMutations('ui', ['toggleMobileSidebar', 'toggleDesktopSidebar']),
    }
}
