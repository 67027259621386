const NO_REDIRECT = ['/setup', '/verify', '/verify/email'];

export default async ({ store, redirect, route, $auth, $sentry }) => {
    if(process.env.APP_ENV !== 'production') console.debug('[MIDDLEWARE] Auth');
    try {
        if (!store.state.auth.isLoggedIn && !$auth.currentUser && route.matched.length && !route.path.includes('auth')) {
            const destination = route.path === '/' || NO_REDIRECT.includes(route.path)
                ? { name: 'auth-login' }
                : {
                    name: 'auth-login',
                    query: {
                        redirect: route.path,
                        ...route.query
                    }
                };

            return redirect(destination);
        }
    } catch (error) {
        if(process.env.APP_ENV !== 'production') console.error(error);
        $sentry.captureException(error, {
            tags: {
                location: 'middleware',
                middleware: 'auth',
            }
        });
    }
}
