import { render, staticRenderFns } from "./DesktopSidebar.vue?vue&type=template&id=7cacb6f5"
import script from "./DesktopSidebar.vue?vue&type=script&lang=js"
export * from "./DesktopSidebar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/app/app/components/common/Logo.vue').default,Navbar: require('/app/app/components/layout/Navbar.vue').default,UserMenu: require('/app/app/components/layout/UserMenu.vue').default})
