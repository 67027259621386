export default async ({ store, redirect, route, $auth, $sentry }) => {
    if (process.env.APP_ENV !== 'production') console.debug('[MIDDLEWARE] Admin');

    const claims = store.state.auth.claims;

    if (claims && claims['https://hasura.io/jwt/claims']) {
        const hasuraClaims = claims['https://hasura.io/jwt/claims'];
        if (!hasuraClaims['x-hasura-default-role'] || hasuraClaims['x-hasura-default-role'] !== 'admin') {
            return redirect({ name: 'index' });
        }
    }
}
