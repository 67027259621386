export const ON_CALL_INTERCOM_VERIFICATION = 'onCall-onCallIntercomVerification';
export const ON_CALL_GET_MEETING_TOKEN = 'onCall-onCallGetMeetingToken';
export const ON_CALL_SETUP_PROVIDER = 'onCall-onCallSetupProvider';
export const ON_CALL_SWITCH_PROVIDER = 'onCall-onCallSwitchProvider';
export const ON_CALL_ACCEPT_INVITATION = 'onCall-onCallAcceptInvitation';
export const ON_CALL_RECAPTCHA_VERIFY = 'onCall-onCallRecaptchaVerify';
export const ON_CALL_CREATE_NOTIFICATION_TOKEN = 'onCall-onCallCreateNotificationToken';
export const ON_CALL_UPDATE_PROFILE = 'onCall-onCallUpdateProfile';
export const ON_CALL_SEND_EMAIL_VERIFICATION = 'onCall-onCallSendEmailVerification';
export const ON_CALL_SEND_RESET_PASSWORD_EMAIL = 'onCall-onCallSendResetPasswordEmail';
export const ON_CALL_ENSURE_CLAIMS = 'onCall-onCallEnsureClaims';
