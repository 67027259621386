import { ref, uploadBytes, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export class Storage {
    constructor($storage) {
        this.storage = $storage;
    }

    /**
     * Upload file
     * @param file
     * @param path
     * @param customMetadata
     * @param onProgress
     * @param onSuccess
     * @returns {Promise<*>}
     */
    async upload({ file, path, customMetadata = {}, onProgress = () => {}, onSuccess = () => {} }) {
        const reference = ref(this.storage, `${path}/${file.uuid}`);

        const upload = uploadBytesResumable(reference, file.raw, {
            name: file.name,
            size: file.size,
            contentType: file.type,
            customMetadata
        });

        upload.on('state_changed', onProgress, (e) => {
            console.error(e);
            this.$sentry.captureException(e);
        });

        return upload.then(async (snapshot) => {
            file.uploaded(await getDownloadURL(snapshot.ref));
            return onSuccess(file);
        });
    }

    /**
     * Upload avatar for a given user
     * @returns {Promise<string>}
     */
    async uploadAvatar({ user, file }) {
        const reference = ref(this.storage, `users/${user.uid}`);

        const snapshot = await uploadBytes(reference, file);

        return getDownloadURL(snapshot.ref);
    }

    /**
     * Upload portal logo for a given provider
     * @returns {Promise<string>}
     */
    async uploadPortalLogo({ provider, file }) {
        const reference = ref(this.storage, `providers/${provider.id}`);

        const snapshot = await uploadBytes(reference, file);

        return getDownloadURL(snapshot.ref);
    }
}
