
export default {
    props: {
        coloured: {
            type: Boolean,
            default: () => false,
        },
        link: {
            type: Object,
            default: () => ({ name: 'index' })
        }
    }
};
