
export default {
    layout: 'full',
    props: ['error'],
    head() {
        return {
            title: this.error.statusCode,
        }
    },
    data() {
        return {
            popularPages: [
                {
                    icon: 'fa-regular fa-address-book',
                    title: 'Client Directory',
                    description: 'Manage your clients and their information.',
                    route: { name: 'directory' }
                },
                {
                    icon: 'fa-regular fa-calendar',
                    title: 'Schedule',
                    description: 'View your schedule and book appointments.',
                    route: { name: 'schedule' }
                }
            ]
        }
    },
    methods: {
        goBack() {
            this.$router.replace({ name: 'index' });
        }
    }
};
