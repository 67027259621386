
import { mapMutations, mapState } from 'vuex';

export default {
    computed: {
        ...mapState('ui', ['mobileSidebarOpen']),
    },
    methods: {
        ...mapMutations('ui', [
            'toggleMobileSidebar',
        ]),
    },
}
