
import { mapState, mapGetters, mapMutations } from 'vuex';
import { MEETING_JOINED, MEETING_LEFT } from '~/constants/events';

export default {
    middleware: ['auth', 'require-verified-email', 'require-practice', 'require-subscription'],
    data() {
        return {
            room: null,
            token: null,
            event: null,
        };
    },
    computed: {
        ...mapGetters('auth', ['role']),
        ...mapState('ui', ['mobileSidebarOpen', 'desktopSidebarOpen']),
        ...mapState('auth', ['isLoggedIn', 'user', 'provider']),
        isDevelopment() {
            return process.env.APP_ENV === 'development';
        }
    },
    methods: {
        ...mapMutations('ui', [
            'toggleMobileSidebar',
            'toggleDesktopSidebar'
        ]),
    },
};
