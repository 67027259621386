export default ({ app: { $sentry } }, inject) => {
    inject('log', {
        debug(message, context) {
            process.env.APP_ENV !== 'production' && console.info(message, context);
            $sentry && $sentry.captureMessage(message, 'info', context);
        },
        info(message, context) {
            process.env.APP_ENV !== 'production' && console.info(message, context);
            $sentry && $sentry.captureMessage(message, 'info', context);
        },
        log(message, context) {
            process.env.APP_ENV !== 'production' && console.log(message, context);
            $sentry && $sentry.captureMessage(message, 'info', context);
        },
        warning(message, context) {
            process.env.APP_ENV !== 'production' && console.warn(message, context);
            $sentry && $sentry.captureMessage(message, 'warning', context);
        },
        error(message, context) {
            process.env.APP_ENV !== 'production' && console.error(message, context);
            $sentry && $sentry.captureMessage(message, 'error', context);
        }
    })
}
