import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _86773c76 = () => interopDefault(import('../app/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _0bb9ce5f = () => interopDefault(import('../app/pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _51609a64 = () => interopDefault(import('../app/pages/account/scheduling.vue' /* webpackChunkName: "pages/account/scheduling" */))
const _5559cefa = () => interopDefault(import('../app/pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _8ffcd1ac = () => interopDefault(import('../app/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _4df524c8 = () => interopDefault(import('../app/pages/directory/index.vue' /* webpackChunkName: "pages/directory/index" */))
const _a363151c = () => interopDefault(import('../app/pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _8597dc8a = () => interopDefault(import('../app/pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _69b60827 = () => interopDefault(import('../app/pages/settings/billing.vue' /* webpackChunkName: "pages/settings/billing" */))
const _3cb64c20 = () => interopDefault(import('../app/pages/settings/portal.vue' /* webpackChunkName: "pages/settings/portal" */))
const _297d483f = () => interopDefault(import('../app/pages/settings/practice.vue' /* webpackChunkName: "pages/settings/practice" */))
const _60418d18 = () => interopDefault(import('../app/pages/settings/scheduling.vue' /* webpackChunkName: "pages/settings/scheduling" */))
const _3b068de2 = () => interopDefault(import('../app/pages/settings/services.vue' /* webpackChunkName: "pages/settings/services" */))
const _1a2a7e61 = () => interopDefault(import('../app/pages/settings/team.vue' /* webpackChunkName: "pages/settings/team" */))
const _646e44e5 = () => interopDefault(import('../app/pages/settings/templates.vue' /* webpackChunkName: "pages/settings/templates" */))
const _5c4159f3 = () => interopDefault(import('../app/pages/settings/templates/consent/index.vue' /* webpackChunkName: "pages/settings/templates/consent/index" */))
const _04763885 = () => interopDefault(import('../app/pages/settings/templates/forms.vue' /* webpackChunkName: "pages/settings/templates/forms" */))
const _1be0a714 = () => interopDefault(import('../app/pages/settings/templates/forms/create.vue' /* webpackChunkName: "pages/settings/templates/forms/create" */))
const _40558748 = () => interopDefault(import('../app/pages/settings/templates/forms/index.vue' /* webpackChunkName: "pages/settings/templates/forms/index" */))
const _46e77720 = () => interopDefault(import('../app/pages/settings/templates/forms/_form/edit.vue' /* webpackChunkName: "pages/settings/templates/forms/_form/edit" */))
const _a2ac894c = () => interopDefault(import('../app/pages/settings/templates/notes/index.vue' /* webpackChunkName: "pages/settings/templates/notes/index" */))
const _4e41faa4 = () => interopDefault(import('../app/pages/settings/templates/notes/create.vue' /* webpackChunkName: "pages/settings/templates/notes/create" */))
const _ed25320c = () => interopDefault(import('../app/pages/settings/templates/consent/_template.vue' /* webpackChunkName: "pages/settings/templates/consent/_template" */))
const _43f26828 = () => interopDefault(import('../app/pages/settings/templates/notes/_template/edit.vue' /* webpackChunkName: "pages/settings/templates/notes/_template/edit" */))
const _1201a650 = () => interopDefault(import('../app/pages/setup/index.vue' /* webpackChunkName: "pages/setup/index" */))
const _12893868 = () => interopDefault(import('../app/pages/switch-practice.vue' /* webpackChunkName: "pages/switch-practice" */))
const _d87e4894 = () => interopDefault(import('../app/pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _18f47b4c = () => interopDefault(import('../app/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _8df5ff7c = () => interopDefault(import('../app/pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _e2f3fa2a = () => interopDefault(import('../app/pages/directory/archives.vue' /* webpackChunkName: "pages/directory/archives" */))
const _62a44ad8 = () => interopDefault(import('../app/pages/setup/join.vue' /* webpackChunkName: "pages/setup/join" */))
const _3d32d525 = () => interopDefault(import('../app/pages/setup/practice.vue' /* webpackChunkName: "pages/setup/practice" */))
const _0c413307 = () => interopDefault(import('../app/pages/setup/subscription.vue' /* webpackChunkName: "pages/setup/subscription" */))
const _51a13fee = () => interopDefault(import('../app/pages/setup/success.vue' /* webpackChunkName: "pages/setup/success" */))
const _f24472c4 = () => interopDefault(import('../app/pages/verify/email.vue' /* webpackChunkName: "pages/verify/email" */))
const _416d05ea = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _17dfae0e = () => interopDefault(import('../app/pages/print/forms/_form.vue' /* webpackChunkName: "pages/print/forms/_form" */))
const _66d86bce = () => interopDefault(import('../app/pages/print/questionnaires/_questionnaire.vue' /* webpackChunkName: "pages/print/questionnaires/_questionnaire" */))
const _221d79c8 = () => interopDefault(import('../app/pages/rooms/appointments/_appointment.vue' /* webpackChunkName: "pages/rooms/appointments/_appointment" */))
const _16b40160 = () => interopDefault(import('../app/pages/directory/_client.vue' /* webpackChunkName: "pages/directory/_client" */))
const _045ae576 = () => interopDefault(import('../app/pages/directory/_client/care-plan.vue' /* webpackChunkName: "pages/directory/_client/care-plan" */))
const _d9890bca = () => interopDefault(import('../app/pages/directory/_client/consents/index.vue' /* webpackChunkName: "pages/directory/_client/consents/index" */))
const _1a6b91f0 = () => interopDefault(import('../app/pages/directory/_client/files.vue' /* webpackChunkName: "pages/directory/_client/files" */))
const _f655397a = () => interopDefault(import('../app/pages/directory/_client/forms/index.vue' /* webpackChunkName: "pages/directory/_client/forms/index" */))
const _578b5d28 = () => interopDefault(import('../app/pages/directory/_client/invoices/index.vue' /* webpackChunkName: "pages/directory/_client/invoices/index" */))
const _19acd156 = () => interopDefault(import('../app/pages/directory/_client/notes/index.vue' /* webpackChunkName: "pages/directory/_client/notes/index" */))
const _23df680a = () => interopDefault(import('../app/pages/directory/_client/overview/index.vue' /* webpackChunkName: "pages/directory/_client/overview/index" */))
const _9184989c = () => interopDefault(import('../app/pages/directory/_client/questionnaires/index.vue' /* webpackChunkName: "pages/directory/_client/questionnaires/index" */))
const _2db1a922 = () => interopDefault(import('../app/pages/directory/_client/settings.vue' /* webpackChunkName: "pages/directory/_client/settings" */))
const _7050e448 = () => interopDefault(import('../app/pages/directory/_client/team.vue' /* webpackChunkName: "pages/directory/_client/team" */))
const _cc84c3ee = () => interopDefault(import('../app/pages/directory/_client/notes/create.vue' /* webpackChunkName: "pages/directory/_client/notes/create" */))
const _a0d68af4 = () => interopDefault(import('../app/pages/directory/_client/overview/personal-information.vue' /* webpackChunkName: "pages/directory/_client/overview/personal-information" */))
const _1011ffe3 = () => interopDefault(import('../app/pages/directory/_client/index.vue' /* webpackChunkName: "pages/directory/_client/index" */))
const _52cfa0d8 = () => interopDefault(import('../app/pages/directory/_client/forms/_form.vue' /* webpackChunkName: "pages/directory/_client/forms/_form" */))
const _47792f92 = () => interopDefault(import('../app/pages/directory/_client/goals/_goal/index.vue' /* webpackChunkName: "pages/directory/_client/goals/_goal/index" */))
const _39afda08 = () => interopDefault(import('../app/pages/directory/_client/invoices/_invoice.vue' /* webpackChunkName: "pages/directory/_client/invoices/_invoice" */))
const _75cdd152 = () => interopDefault(import('../app/pages/directory/_client/notes/_note/index.vue' /* webpackChunkName: "pages/directory/_client/notes/_note/index" */))
const _bce10018 = () => interopDefault(import('../app/pages/directory/_client/questionnaires/_questionnaire.vue' /* webpackChunkName: "pages/directory/_client/questionnaires/_questionnaire" */))
const _dd414f96 = () => interopDefault(import('../app/pages/directory/_client/goals/_goal/edit.vue' /* webpackChunkName: "pages/directory/_client/goals/_goal/edit" */))
const _2d874515 = () => interopDefault(import('../app/pages/directory/_client/notes/_note/edit.vue' /* webpackChunkName: "pages/directory/_client/notes/_note/edit" */))
const _7f5d2b3b = () => interopDefault(import('../app/pages/invitations/_invitation.vue' /* webpackChunkName: "pages/invitations/_invitation" */))
const _d6459f20 = () => interopDefault(import('../app/pages/schedule/_appointment.vue' /* webpackChunkName: "pages/schedule/_appointment" */))
const _5f0f5a92 = () => interopDefault(import('../app/pages/print/_client/notes.vue' /* webpackChunkName: "pages/print/_client/notes" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _86773c76,
    pathToRegexpOptions: {"strict":true},
    name: "account",
    children: [{
      path: "profile",
      component: _0bb9ce5f,
      pathToRegexpOptions: {"strict":true},
      name: "account-profile"
    }, {
      path: "scheduling",
      component: _51609a64,
      pathToRegexpOptions: {"strict":true},
      name: "account-scheduling"
    }, {
      path: "security",
      component: _5559cefa,
      pathToRegexpOptions: {"strict":true},
      name: "account-security"
    }]
  }, {
    path: "/admin",
    component: _8ffcd1ac,
    pathToRegexpOptions: {"strict":true},
    name: "admin"
  }, {
    path: "/directory",
    component: _4df524c8,
    pathToRegexpOptions: {"strict":true},
    name: "directory"
  }, {
    path: "/schedule",
    component: _a363151c,
    pathToRegexpOptions: {"strict":true},
    name: "schedule"
  }, {
    path: "/settings",
    component: _8597dc8a,
    pathToRegexpOptions: {"strict":true},
    name: "settings",
    children: [{
      path: "billing",
      component: _69b60827,
      pathToRegexpOptions: {"strict":true},
      name: "settings-billing"
    }, {
      path: "portal",
      component: _3cb64c20,
      pathToRegexpOptions: {"strict":true},
      name: "settings-portal"
    }, {
      path: "practice",
      component: _297d483f,
      pathToRegexpOptions: {"strict":true},
      name: "settings-practice"
    }, {
      path: "scheduling",
      component: _60418d18,
      pathToRegexpOptions: {"strict":true},
      name: "settings-scheduling"
    }, {
      path: "services",
      component: _3b068de2,
      pathToRegexpOptions: {"strict":true},
      name: "settings-services"
    }, {
      path: "team",
      component: _1a2a7e61,
      pathToRegexpOptions: {"strict":true},
      name: "settings-team"
    }, {
      path: "templates",
      component: _646e44e5,
      pathToRegexpOptions: {"strict":true},
      name: "settings-templates",
      children: [{
        path: "consent",
        component: _5c4159f3,
        pathToRegexpOptions: {"strict":true},
        name: "settings-templates-consent"
      }, {
        path: "forms",
        component: _04763885,
        pathToRegexpOptions: {"strict":true},
        children: [{
          path: "create",
          component: _1be0a714,
          pathToRegexpOptions: {"strict":true},
          name: "settings-templates-forms-create"
        }, {
          path: "",
          component: _40558748,
          pathToRegexpOptions: {"strict":true},
          name: "settings-templates-forms"
        }, {
          path: ":form/edit",
          component: _46e77720,
          pathToRegexpOptions: {"strict":true},
          name: "settings-templates-forms-form-edit"
        }]
      }, {
        path: "notes",
        component: _a2ac894c,
        pathToRegexpOptions: {"strict":true},
        name: "settings-templates-notes"
      }, {
        path: "notes/create",
        component: _4e41faa4,
        pathToRegexpOptions: {"strict":true},
        name: "settings-templates-notes-create"
      }, {
        path: "consent/:template",
        component: _ed25320c,
        pathToRegexpOptions: {"strict":true},
        name: "settings-templates-consent-template"
      }, {
        path: "notes/:template/edit",
        component: _43f26828,
        pathToRegexpOptions: {"strict":true},
        name: "settings-templates-notes-template-edit"
      }]
    }]
  }, {
    path: "/setup",
    component: _1201a650,
    pathToRegexpOptions: {"strict":true},
    name: "setup"
  }, {
    path: "/switch-practice",
    component: _12893868,
    pathToRegexpOptions: {"strict":true},
    name: "switch-practice"
  }, {
    path: "/auth/forgot-password",
    component: _d87e4894,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _18f47b4c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _8df5ff7c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register"
  }, {
    path: "/directory/archives",
    component: _e2f3fa2a,
    pathToRegexpOptions: {"strict":true},
    name: "directory-archives"
  }, {
    path: "/setup/join",
    component: _62a44ad8,
    pathToRegexpOptions: {"strict":true},
    name: "setup-join"
  }, {
    path: "/setup/practice",
    component: _3d32d525,
    pathToRegexpOptions: {"strict":true},
    name: "setup-practice"
  }, {
    path: "/setup/subscription",
    component: _0c413307,
    pathToRegexpOptions: {"strict":true},
    name: "setup-subscription"
  }, {
    path: "/setup/success",
    component: _51a13fee,
    pathToRegexpOptions: {"strict":true},
    name: "setup-success"
  }, {
    path: "/verify/email",
    component: _f24472c4,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email"
  }, {
    path: "/",
    component: _416d05ea,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/print/forms/:form?",
    component: _17dfae0e,
    pathToRegexpOptions: {"strict":true},
    name: "print-forms-form"
  }, {
    path: "/print/questionnaires/:questionnaire?",
    component: _66d86bce,
    pathToRegexpOptions: {"strict":true},
    name: "print-questionnaires-questionnaire"
  }, {
    path: "/rooms/appointments/:appointment?",
    component: _221d79c8,
    pathToRegexpOptions: {"strict":true},
    name: "rooms-appointments-appointment"
  }, {
    path: "/directory/:client",
    component: _16b40160,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "care-plan",
      component: _045ae576,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-care-plan"
    }, {
      path: "consents",
      component: _d9890bca,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-consents"
    }, {
      path: "files",
      component: _1a6b91f0,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-files"
    }, {
      path: "forms",
      component: _f655397a,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-forms"
    }, {
      path: "invoices",
      component: _578b5d28,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-invoices"
    }, {
      path: "notes",
      component: _19acd156,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-notes"
    }, {
      path: "overview",
      component: _23df680a,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-overview"
    }, {
      path: "questionnaires",
      component: _9184989c,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-questionnaires"
    }, {
      path: "settings",
      component: _2db1a922,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-settings"
    }, {
      path: "team",
      component: _7050e448,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-team"
    }, {
      path: "notes/create",
      component: _cc84c3ee,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-notes-create"
    }, {
      path: "overview/personal-information",
      component: _a0d68af4,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-overview-personal-information"
    }, {
      path: "",
      component: _1011ffe3,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client"
    }, {
      path: "forms/:form",
      component: _52cfa0d8,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-forms-form"
    }, {
      path: "goals/:goal",
      component: _47792f92,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-goals-goal"
    }, {
      path: "invoices/:invoice",
      component: _39afda08,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-invoices-invoice"
    }, {
      path: "notes/:note",
      component: _75cdd152,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-notes-note"
    }, {
      path: "questionnaires/:questionnaire",
      component: _bce10018,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-questionnaires-questionnaire"
    }, {
      path: "goals/:goal?/edit",
      component: _dd414f96,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-goals-goal-edit"
    }, {
      path: "notes/:note/edit",
      component: _2d874515,
      pathToRegexpOptions: {"strict":true},
      name: "directory-client-notes-note-edit"
    }]
  }, {
    path: "/invitations/:invitation?",
    component: _7f5d2b3b,
    pathToRegexpOptions: {"strict":true},
    name: "invitations-invitation"
  }, {
    path: "/schedule/:appointment",
    component: _d6459f20,
    pathToRegexpOptions: {"strict":true},
    name: "schedule-appointment"
  }, {
    path: "/print/:client?/notes",
    component: _5f0f5a92,
    pathToRegexpOptions: {"strict":true},
    name: "print-client-notes"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
