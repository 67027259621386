export default ({ redirect, route, store, app: { $sentry } }) => {
    try {
        console.debug('[MIDDLEWARE] Require a verified email');

        if(route.matched.length && !route.path.includes('auth') && store.state.auth.isLoggedIn && !store.state.auth.user.emailVerified) {
            try {
                return redirect({ name: 'verify-email' });
            } catch(error) {
                console.warn(error);
                $sentry.captureException(error);
            }
        }
    } catch(error) {
        $sentry.captureException(error, {
            tags: {
                location: 'middleware',
                middleware: 'require-verified-email'
            }
        })
    }
}
