export const SxAlert = () => import('../../node_modules/@speakbox/ui-core/components/Alert.vue' /* webpackChunkName: "components/sx-alert" */).then(c => wrapFunctional(c.default || c))
export const SxAvatar = () => import('../../node_modules/@speakbox/ui-core/components/Avatar.vue' /* webpackChunkName: "components/sx-avatar" */).then(c => wrapFunctional(c.default || c))
export const SxButton = () => import('../../node_modules/@speakbox/ui-core/components/Button.vue' /* webpackChunkName: "components/sx-button" */).then(c => wrapFunctional(c.default || c))
export const SxDate = () => import('../../node_modules/@speakbox/ui-core/components/Date.vue' /* webpackChunkName: "components/sx-date" */).then(c => wrapFunctional(c.default || c))
export const SxDivider = () => import('../../node_modules/@speakbox/ui-core/components/Divider.vue' /* webpackChunkName: "components/sx-divider" */).then(c => wrapFunctional(c.default || c))
export const SxDropdown = () => import('../../node_modules/@speakbox/ui-core/components/Dropdown.vue' /* webpackChunkName: "components/sx-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SxDropdownItem = () => import('../../node_modules/@speakbox/ui-core/components/DropdownItem.vue' /* webpackChunkName: "components/sx-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const SxEmptyState = () => import('../../node_modules/@speakbox/ui-core/components/EmptyState.vue' /* webpackChunkName: "components/sx-empty-state" */).then(c => wrapFunctional(c.default || c))
export const SxEmptyStateWithAction = () => import('../../node_modules/@speakbox/ui-core/components/EmptyStateWithAction.vue' /* webpackChunkName: "components/sx-empty-state-with-action" */).then(c => wrapFunctional(c.default || c))
export const SxFlyout = () => import('../../node_modules/@speakbox/ui-core/components/Flyout.vue' /* webpackChunkName: "components/sx-flyout" */).then(c => wrapFunctional(c.default || c))
export const SxLink = () => import('../../node_modules/@speakbox/ui-core/components/Link.vue' /* webpackChunkName: "components/sx-link" */).then(c => wrapFunctional(c.default || c))
export const SxLoader = () => import('../../node_modules/@speakbox/ui-core/components/Loader.vue' /* webpackChunkName: "components/sx-loader" */).then(c => wrapFunctional(c.default || c))
export const SxModal = () => import('../../node_modules/@speakbox/ui-core/components/Modal.vue' /* webpackChunkName: "components/sx-modal" */).then(c => wrapFunctional(c.default || c))
export const SxModalFullscreen = () => import('../../node_modules/@speakbox/ui-core/components/ModalFullscreen.vue' /* webpackChunkName: "components/sx-modal-fullscreen" */).then(c => wrapFunctional(c.default || c))
export const SxPaginationMonth = () => import('../../node_modules/@speakbox/ui-core/components/PaginationMonth.vue' /* webpackChunkName: "components/sx-pagination-month" */).then(c => wrapFunctional(c.default || c))
export const SxPhoneInput = () => import('../../node_modules/@speakbox/ui-core/components/PhoneInput.vue' /* webpackChunkName: "components/sx-phone-input" */).then(c => wrapFunctional(c.default || c))
export const SxPhoneInputInternational = () => import('../../node_modules/@speakbox/ui-core/components/PhoneInputInternational.vue' /* webpackChunkName: "components/sx-phone-input-international" */).then(c => wrapFunctional(c.default || c))
export const SxSignature = () => import('../../node_modules/@speakbox/ui-core/components/Signature.vue' /* webpackChunkName: "components/sx-signature" */).then(c => wrapFunctional(c.default || c))
export const SxTabs = () => import('../../node_modules/@speakbox/ui-core/components/Tabs.vue' /* webpackChunkName: "components/sx-tabs" */).then(c => wrapFunctional(c.default || c))
export const ActionCard = () => import('../../app/components/common/ActionCard.vue' /* webpackChunkName: "components/action-card" */).then(c => wrapFunctional(c.default || c))
export const ActionCardItem = () => import('../../app/components/common/ActionCardItem.vue' /* webpackChunkName: "components/action-card-item" */).then(c => wrapFunctional(c.default || c))
export const AddressField = () => import('../../app/components/common/AddressField.vue' /* webpackChunkName: "components/address-field" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbItem = () => import('../../app/components/common/BreadcrumbItem.vue' /* webpackChunkName: "components/breadcrumb-item" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../app/components/common/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../app/components/common/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CollaborationStatus = () => import('../../app/components/common/CollaborationStatus.vue' /* webpackChunkName: "components/collaboration-status" */).then(c => wrapFunctional(c.default || c))
export const DocumentationLink = () => import('../../app/components/common/DocumentationLink.vue' /* webpackChunkName: "components/documentation-link" */).then(c => wrapFunctional(c.default || c))
export const EditableField = () => import('../../app/components/common/EditableField.vue' /* webpackChunkName: "components/editable-field" */).then(c => wrapFunctional(c.default || c))
export const FormSection = () => import('../../app/components/common/FormSection.vue' /* webpackChunkName: "components/form-section" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../app/components/common/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../app/components/common/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const RadioGroup = () => import('../../app/components/common/RadioGroup.vue' /* webpackChunkName: "components/radio-group" */).then(c => wrapFunctional(c.default || c))
export const RichTextField = () => import('../../app/components/common/RichTextField.vue' /* webpackChunkName: "components/rich-text-field" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../app/components/common/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const ShareLink = () => import('../../app/components/common/ShareLink.vue' /* webpackChunkName: "components/share-link" */).then(c => wrapFunctional(c.default || c))
export const SxSortIcon = () => import('../../app/components/common/SxSortIcon.vue' /* webpackChunkName: "components/sx-sort-icon" */).then(c => wrapFunctional(c.default || c))
export const Table = () => import('../../app/components/common/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c))
export const TableHeaderColumn = () => import('../../app/components/common/TableHeaderColumn.vue' /* webpackChunkName: "components/table-header-column" */).then(c => wrapFunctional(c.default || c))
export const WorkingHours = () => import('../../app/components/common/WorkingHours.vue' /* webpackChunkName: "components/working-hours" */).then(c => wrapFunctional(c.default || c))
export const Editor = () => import('../../app/components/common/editor/Editor.vue' /* webpackChunkName: "components/editor" */).then(c => wrapFunctional(c.default || c))
export const EditorMenu = () => import('../../app/components/common/editor/EditorMenu.vue' /* webpackChunkName: "components/editor-menu" */).then(c => wrapFunctional(c.default || c))
export const HeadingDetailed = () => import('../../app/components/common/heading/Detailed.vue' /* webpackChunkName: "components/heading-detailed" */).then(c => wrapFunctional(c.default || c))
export const HeadingSimple = () => import('../../app/components/common/heading/Simple.vue' /* webpackChunkName: "components/heading-simple" */).then(c => wrapFunctional(c.default || c))
export const HeadingWithActions = () => import('../../app/components/common/heading/WithActions.vue' /* webpackChunkName: "components/heading-with-actions" */).then(c => wrapFunctional(c.default || c))
export const HeadingWithNavigation = () => import('../../app/components/common/heading/WithNavigation.vue' /* webpackChunkName: "components/heading-with-navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationHorizontal = () => import('../../app/components/common/navigation/Horizontal.vue' /* webpackChunkName: "components/navigation-horizontal" */).then(c => wrapFunctional(c.default || c))
export const SidebarTeam = () => import('../../app/components/common/sidebar/Team.vue' /* webpackChunkName: "components/sidebar-team" */).then(c => wrapFunctional(c.default || c))
export const SidebarTeamHeader = () => import('../../app/components/common/sidebar/TeamHeader.vue' /* webpackChunkName: "components/sidebar-team-header" */).then(c => wrapFunctional(c.default || c))
export const EditorTemplatesSelectTemplate = () => import('../../app/components/common/editor/templates/SelectTemplate.vue' /* webpackChunkName: "components/editor-templates-select-template" */).then(c => wrapFunctional(c.default || c))
export const DesktopSidebar = () => import('../../app/components/layout/DesktopSidebar.vue' /* webpackChunkName: "components/desktop-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LaunchNotes = () => import('../../app/components/layout/LaunchNotes.vue' /* webpackChunkName: "components/launch-notes" */).then(c => wrapFunctional(c.default || c))
export const MainHeader = () => import('../../app/components/layout/MainHeader.vue' /* webpackChunkName: "components/main-header" */).then(c => wrapFunctional(c.default || c))
export const MobileSidebar = () => import('../../app/components/layout/MobileSidebar.vue' /* webpackChunkName: "components/mobile-sidebar" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../app/components/layout/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../app/components/layout/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const SideNavigation = () => import('../../app/components/layout/SideNavigation.vue' /* webpackChunkName: "components/side-navigation" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../app/components/layout/UserMenu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
