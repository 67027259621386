/**
 * Analytics events
 * NOTE: These events may be used with the Event Bus, in the process of migrating to event-bus.js
 * REFERENCE: https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 */
export const ACCOUNT_SETUP = 'Account Setup';

export const ACTION_ITEM_CREATED = 'Action Item Created';
export const ACTION_ITEM_COMPLETED = 'Action Item Completed';

export const APPOINTMENT_SCHEDULED = 'Appointment Scheduled';
export const APPOINTMENT_RESCHEDULED = 'Appointment Rescheduled';
export const APPOINTMENT_CANCELLED = 'Appointment Cancelled';
export const APPOINTMENT_SERVICES_UPDATED = 'Appointment Services Updated';
export const APPOINTMENT_SCRATCHPAD_UPDATED = 'Appointment Scratchpad Updated';
export const APPOINTMENT_SCRATCHPAD_VISIBILITY_UPDATED = 'Appointment Scratchpad Visibility Updated';

export const AVAILABILITY_SCHEDULED = 'Availability Scheduled';
export const AVAILABILITY_DELETED = 'Availability Deleted';

export const CALENDAR_FEED_COPIED = 'Calendar Feed Copied';

export const CHECKOUT_SESSION_INITIATED = 'Checkout Session Initiated';

export const CLIENT_CREATED = 'Client Created';
export const CLIENT_INVITED = 'Client Invited';
export const CLIENT_INVITATION_LINK_COPIED = 'Invitation Link Copied';

export const CONSENT_TEMPLATE_CREATED = 'Consent Template Created';
export const CONSENT_TEMPLATE_DELETED = 'Consent Template Deleted';
export const CONSENT_REQUESTED = 'Consent Requested';
export const CONSENT_EXPIRED = 'Consent Expired';

export const DISCIPLINE_CREATED = 'Discipline Created';
export const DISCIPLINE_UPDATED = 'Discipline Updated';
export const DISCIPLINE_DELETED = 'Discipline Deleted';

export const FILES_UPLOADED = 'Files Uploaded';
export const FILES_UPDATED = 'Files Updated';
export const FILES_DELETED = 'Files Deleted';
export const FILES_SWITCH_VIEW = 'Files View Switched';

export const FORM_SENT = 'Form Sent';
export const FORM_FILLED = 'Form Filled';

export const INVITATION_SENT = 'Invitation Sent';
export const INVITATION_ACCEPTED = 'Invitation Accepted';
export const INVITATION_DENIED = 'Invitation Denied';

export const GOAL_CREATED = 'Goal Created';
export const GOAL_UPDATED = 'Goal Updated';
export const GOAL_STARTED = 'Goal Started';
export const GOAL_COMPLETED = 'Goal Completed';
export const GOAL_DELETED = 'Goal Deleted';

export const GOAL_OBJECTIVE_CREATED = 'Goal Objective Created';
export const GOAL_OBJECTIVE_UPDATED = 'Goal Objective Updated';
export const GOAL_OBJECTIVE_DELETED = 'Goal Objective Deleted';

export const GOAL_REVIEW_CREATED = 'Goal Review Created';
export const GOAL_REVIEW_DELETED = 'Goal Review Deleted';

export const MEETING_JOINED = 'Meeting Joined';
export const MEETING_EXITED = 'Meeting Exited';
export const MEETING_LEFT = 'Meeting Left';

export const NOTE_CREATED = 'Note Created';
export const NOTE_UPDATED = 'Note Updated';
export const NOTE_DELETED = 'Note Deleted';
export const NOTE_LOCKED = 'Note Locked';
export const NOTE_SHARED = 'Note Shared';
export const NOTE_HIDDEN = 'Note Hidden';

export const PORTAL_PUBLISHED = 'Portal Published';
export const PORTAL_UNPUBLISHED = 'Portal Unpublished';
export const PORTAL_UPDATED = 'Portal Updated';

export const PRACTICE_SETUP = 'Practice Setup';

export const QUESTIONNAIRE_SENT = 'Questionnaire Sent';
export const QUESTIONNAIRE_FILLED = 'Questionnaire Filled';

export const SERVICE_CREATED = 'Service Created';
export const SERVICE_UPDATED = 'Service Updated';
export const SERVICE_DELETED = 'Service Deleted';

export const SUBSCRIPTION_CREATED = 'Subscription Created';

export const SETTINGS_CONSENT_UPDATED = 'Consent Settings Updated';
export const SETTINGS_PRACTICE_UPDATED = 'Practice Settings Updated';
export const SETTINGS_SCHEDULING_UPDATED = 'Scheduling Settings Updated';
export const SETTINGS_SUBSCRIPTION_VISITED = 'Subscription Settings Visited';

export const USER_LOGGED_IN = 'User Logged In';
export const USER_LOGGED_OUT = 'User Logged Out';
export const USER_RESET_PASSWORD = 'User Reset Password';
export const USER_REGISTERED = 'User Registered';
