import { getIdTokenResult } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { ON_CALL_ENSURE_CLAIMS } from '~/constants/functions';

export default async ({ app: { store, $auth, $functions } }) => {
    console.debug('[MIDDLEWARE] Require hasura claims');

    return new Promise(async (resolve) => {
        // If user is not authenticated, skip this middleware
        if (!store.state.auth.isLoggedIn || !store.state.auth.user.uid) {
            return resolve(true);
        }

        // If user does not have claims, enforce them
        if (!store.state.auth.claims['https://hasura.io/jwt/claims']) {
            if(process.env.APP_ENV !== 'production') console.debug('[CLAIMS] User does not have hasura claims');
            const onCallEnsureClaims = httpsCallable($functions, ON_CALL_ENSURE_CLAIMS);

            await onCallEnsureClaims({
                uid: store.state.auth.user.uid,
                tenantId: process.env.FIREBASE_TENANT_ID
            });

            await refreshToken($auth.currentUser, store);

            return resolve(true);
        }

        if(!store.state.auth.membership && store.state.auth.claims['https://hasura.io/jwt/claims']['x-hasura-current-provider']) {
            console.debug("[CLAIMS] User has claims for a provider but no membership");
            await refreshToken($auth.currentUser, store);
            return resolve(true);
        }

        if(store.state.auth.membership && store.state.auth.claims['https://hasura.io/jwt/claims']['x-hasura-provider-role'] !==  store.state.auth.membership.role) {
            console.debug("[CLAIMS] Membership role does not match hasura role, updating membership");
            await refreshToken($auth.currentUser, store);
            return resolve(true);
        }

        return resolve(true);
    });
}

const refreshToken = async (user, store) => {
    const jwt = await getIdTokenResult(user, true);
    return store.dispatch('auth/login', { user, jwt });
}
