const contentful = require("contentful");

export default (context, inject) => {
    if(!process.env.CONTENTFUL_SPACE_ID || !process.env.CONTENTFUL_API_KEY || !process.env.CONTENTFUL_BRANCH) {
        return false;
    }

    const client = contentful.createClient({
        space: process.env.CONTENTFUL_SPACE_ID,
        environment: process.env.CONTENTFUL_BRANCH,
        accessToken: process.env.CONTENTFUL_API_KEY
    });

    inject('contentful', client);
}
