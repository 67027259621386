export default async({ redirect, store, route, app: { $sentry } }) => {
    console.debug('[MIDDLEWARE] Require an active subscription');

    try{
        if (route.matched.length && (!store.state.auth.subscriptions || store.state.auth.subscriptions.length === 0)) {
            return redirect({ name: 'setup' });
        }

        const hasActiveSubscription = store.state.auth.subscriptions.some(subscription => {
            return ['active', 'incomplete', 'past_due', 'trialing'].includes(subscription.status)
                && !subscription.pause_collection;
        });

        if(!hasActiveSubscription) {
            return redirect({ name: 'setup-subscription' });
        }

        return true;
    } catch(error) {
        $sentry.captureException(error, {
            tags: {
                location: 'middleware',
                middleware: 'require-subscription'
            }
        })
    }
};
